import React, { memo, useEffect, useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import { OffsetContainer, JDoodleContainer, GlobalStyle } from './styles'
import config from '../../config'
import { render } from 'datocms-structured-text-to-plain-text'
import {
  formatCode,
  getCodeFromLocalStorage,
  saveCodeToLocalStorage
} from '../../utilities/jdoodleUtils'
import {
  JDOODLE_COMPILER_SCRIPT,
  JDOODLE_COMPILER_KEY,
  DEFAULT_THEME,
  THEMES
} from '../../Constants/jdoodle'
import Context from '../Context/Context'

function JDoodleCompiler ({
  questionUUID,
  isALRedesign,
  starterCode,
  saveEditorContent,
  isPartOfQuestion
}) {
  const { code: defaultCode } = starterCode?.value?.document?.children?.[0] || {}

  const { isNotStudent, isContractorEmployee, cohortData } = useContext(Context)
  const { testAttempt } = cohortData || false
  const isOutlierStudent = !isContractorEmployee && !testAttempt && !isNotStudent

  const codeFromLocalStorage = useMemo(() => {
    if (!questionUUID || isPartOfQuestion) return null

    const savedCode = getCodeFromLocalStorage(questionUUID)
    if (!savedCode) return null

    return formatCode(savedCode)
    // eslint-disable-next-line
  }, [questionUUID])

  useEffect(() => {
    const existingScript = document.querySelector('#jdoodleCompiler')
    if (existingScript) existingScript.parentNode.removeChild(existingScript)

    const jDoodleScript = document.createElement('script')
    jDoodleScript.src = JDOODLE_COMPILER_SCRIPT
    jDoodleScript.type = JDOODLE_COMPILER_KEY
    jDoodleScript.id = 'jdoodleCompiler'
    document.body.append(jDoodleScript)

    // TODO: Remove this logic after the course recording is completed
    if (isOutlierStudent) {
      jDoodleScript.onload = () => {
        setTimeout(() => {
          if (window.pym && window.pym.autoInitInstances && window.pym.autoInitInstances[0]) {
            window.pym.autoInitInstances[0].sendMessage('jddisablepaste')
          } else {
            console.error('JDoodle script not loaded')
          }
        })
      }
    }

    const messageHandler = event => {
      if (event.origin === 'https://outlier.jdoodle.com' && event.data.script) {
        const message = event.data.script
        !isPartOfQuestion && message !== defaultCode &&
          saveCodeToLocalStorage({ [questionUUID]: message })
        if (!saveEditorContent) return

        saveEditorContent(message)
      }
    }

    window.addEventListener('message', messageHandler)

    return () => {
      window.removeEventListener('message', messageHandler)
    }

    // eslint-disable-next-line
  }, [])

  const theme = config.jDoodleTheme
  const isValidTheme = THEMES.includes(theme) && theme

  return (
    <OffsetContainer id='offset-container'>
      <GlobalStyle isALRedesign={isALRedesign} />
      <JDoodleContainer
        isCodeEditorOnly={isPartOfQuestion}
        id='jdoodle-compiler-container'
        data-code-editor-only={isPartOfQuestion}
        data-theme={isValidTheme || DEFAULT_THEME}
        data-pym-src='https://outlier.jdoodle.com/embed/simple'
        data-language='java'
        data-version-index='4'
        data-libs='mavenlib1, mavenlib2'
      >
        {render(codeFromLocalStorage || starterCode)}
      </JDoodleContainer>
    </OffsetContainer>
  )
}

JDoodleCompiler.propTypes = {
  questionUUID: PropTypes.string,
  isALRedesign: PropTypes.bool,
  starterCode: PropTypes.object,
  saveEditorContent: PropTypes.func,
  isPartOfQuestion: PropTypes.bool
}

JDoodleCompiler.displayName = 'JDoodleCompiler'

export default memo(JDoodleCompiler)
