import api from '../api'
import jwtDecode from 'jwt-decode'
import config, { CAAO_TEAM_MEMBER } from '../config'
import {
  CONTENT,
  AUDIT,
  AUDIT_TRANSCRIPT,
  WITHDRAW
} from '../Constants/studentStatus'
import { getLatestCohort } from './courseUtils'
import {
  CONTENT_USERS } from '../Constants'
import {
  dateToSecondsSinceEpoch,
  secondsSinceEpoch
} from './dateTimeUtils'
import { hasCourseProgress } from './studentProgressUtils'

export {
  isConsultant,
  overrideTOS,
  onboardingFormSubmitted,
  isContentUser,
  getUserEmail,
  isNoAssessmentsCheck,
  isUserAdmin,
  isUserAuditor,
  isVIPAccount,
  isVIPGradedContentAccount,
  canUseStudentEmailOverride,
  getStudentAge,
  needsGuardianPermission,
  shouldRepeatGuardianPermission,
  showTermsAgreement,
  shouldLockAssessments,
  isLatestTermsAccepted,
  isLatestTOSDate,
  isOutlierAccount,
  isVIPCohort,
  isSavvasAccount,
  isCAAOTeamMember
}

async function getUserEmail () {
  const token = await api.getToken()
  if (!token) return ''

  const decodedToken = jwtDecode(token)
  return decodedToken.email
}

async function isUserAdmin () {
  if (config.isStudent()) return false
  const email = await getUserEmail()
  return isOutlierAccount(email)
}

function isSavvasAccount (email = '') {
  if (!email) return false

  return email.endsWith('@savvas.com')
}

function isOutlierAccount (email = '') {
  if (!email) return false

  return email.endsWith('@outlier.org') ||
    email.endsWith('@engineering.outlier.org') || isConsultant(email)
}

function isConsultant (email = '') {
  return email?.endsWith('@consultant.outlier.org')
}

function isVIPAccount (course) {
  if (!course) return false
  // VIPGRADEDCONTENT have also VIP checked in AT, check #3813 for details
  return !!course?.vip && !course?.vipGradedContent
}

function isVIPGradedContentAccount (course) {
  if (!course) return false
  return !!course?.vipGradedContent
}

function isUserAuditor (latestCohort) {
  if (!latestCohort) return false

  const { name, studentStatus } = latestCohort
  return !!([AUDIT, AUDIT_TRANSCRIPT].includes(studentStatus) ||
    name?.toLowerCase().includes('audit'))
}

function isNoAssessmentsCheck (course) {
  if (!course) return

  const latestCohort = getLatestCohort(course)

  if (latestCohort) {
    const { studentStatus } = latestCohort
    if ([WITHDRAW].includes(studentStatus)) return true
  }

  return course.noAssessments
}

function shouldLockAssessments ({ isAdmin, course, isVIP }) {
  // normal VIP accounts does not have access to graded content (Exam, Quiz)
  return (isNoAssessmentsCheck(course) && !isAdmin) || isVIP
}

async function canUseStudentEmailOverride () {
  const isAdmin = await isUserAdmin()
  return !!(isAdmin && config.studentEmailOverride)
}

async function isContentUser (userEmail, studentStatus) {
  const hasContentStudentStatus = studentStatus === CONTENT
  if (hasContentStudentStatus) return true

  const contentUsers = CONTENT_USERS.map(user => user.toLowerCase())
  const isAContentUser = contentUsers.includes(userEmail.toLowerCase())
  if (isAContentUser) return true

  const hasContentAccess = await getContentCreatorAccess()
  return hasContentAccess
}

async function getContentCreatorAccess () {
  const { contentCreatorAccess } = await api.getContentCreatorPermission() || {}

  return contentCreatorAccess
}

function getStudentAge (dateOfBirth) {
  if (!dateOfBirth) return

  const birthDate = new Date(dateOfBirth).getTime() / 1000
  if (!birthDate || isNaN(birthDate)) return

  const secondsInAYear = 365 * 24 * 60 * 60
  const secondsSinceBirth = secondsSinceEpoch() - birthDate
  return Math.floor(secondsSinceBirth / secondsInAYear)
}

function showTermsAgreement ({
  termsAgreementOverride,
  dateTOS,
  under13,
  guardianTOSDate,
  studentData,
  isProfCertCourse,
  gguParentCertificationFormNotSubmitted,
  isActiveGGUStudent,
  dateOfBirth
}) {
  if (config.course.repeatGuardianPermissionRequest) {
    const needsPermission = shouldRepeatGuardianPermission({
      dateOfBirth, under13, guardianTOSDate
    })
    if (needsPermission) {
      const showGuardianPermission = !isLatestTermsAccepted(
        guardianTOSDate, config.repeatGuardianPermissionCutOffDate
      )
      if (showGuardianPermission) return { showGuardianPermission }
    }
  }

  // A student is returning, if they have previously got guardian permission
  // but there parent has not submitted the GGU Parent Certification Form
  const isReturningStudent = !!guardianTOSDate && gguParentCertificationFormNotSubmitted
  const needsPermission = needsGuardianPermission({ dateOfBirth, under13 })

  const showProfCertGuardianPermission = isProfCertCourse &&
    isReturningStudent &&
    !isActiveGGUStudent &&
    needsPermission

  if (showProfCertGuardianPermission) {
    return {
      showProfCertGuardianPermission
    }
  }

  const hasStartedCourse = hasCourseProgress(studentData)
  if (hasStartedCourse) return {}

  if (termsAgreementOverride) return {}

  if (needsPermission) {
    return {
      showGuardianPermission: !isLatestTermsAccepted(guardianTOSDate)
    }
  }

  return { showTermsModal: !isLatestTermsAccepted(dateTOS) }
}

function shouldRepeatGuardianPermission ({ dateOfBirth, under13, guardianTOSDate }) {
  const hasPriorPermission = !!guardianTOSDate
  if (!hasPriorPermission) return false

  const studentAge = getStudentAge(dateOfBirth)
  return studentAge < 13 ? !under13 : studentAge <= 15
}

function needsGuardianPermission ({ dateOfBirth, under13 }) {
  const studentAge = getStudentAge(dateOfBirth)

  /* Students whose age is below 13, but have their under13 column
  unchecked in Airtable, are allowed to access site after getting
  guardian permission */
  return studentAge < 13 ? !under13 : studentAge <= 17
}

function isLatestTermsAccepted (
  dateTOS, latestTermsUpdatedDate = config.termsUpdatedDate
) {
  if (!dateTOS) return false

  const termsUpdatedDate = getTermsUpdatedDate(latestTermsUpdatedDate)
  const dateTOSInSeconds = dateToSecondsSinceEpoch(new Date(dateTOS))
  return dateTOSInSeconds > termsUpdatedDate
}

function isLatestTOSDate (dateTOS) {
  if (!dateTOS) return false

  const { course, repeatGuardianPermissionCutOffDate, termsUpdatedDate } = config
  const termsUpdateCutOffDate = course.repeatGuardianPermissionRequest
    ? repeatGuardianPermissionCutOffDate : termsUpdatedDate

  return isLatestTermsAccepted(dateTOS, termsUpdateCutOffDate)
}

function getTermsUpdatedDate (latestTermsUpdatedDate) {
  return dateToSecondsSinceEpoch(new Date(latestTermsUpdatedDate))
}

function onboardingFormSubmitted (studentData) {
  if (!studentData) return false

  const {
    firstName,
    lastName,
    dateOfBirth,
    isVIP,
    isVIPGradedContent,
    enrollingStudentCertification
  } = studentData

  if (isVIP || isVIPGradedContent) return true

  return !!(firstName && lastName && dateOfBirth && enrollingStudentCertification)
}

function overrideTOS (latestCohort) {
  if (!latestCohort) return false

  const {
    relationship: { fields: { hideTOS } = {} } = {}
  } = latestCohort
  return hideTOS
}

function isVIPCohort (cohortData) {
  const cohortName = cohortData?.name?.toLowerCase() || ''
  return cohortName.includes('vip')
}

function isCAAOTeamMember (roles = []) {
  return roles?.length > 0 && roles?.every(item => item === CAAO_TEAM_MEMBER)
}
